import React, { useState, useEffect } from "react";

export default function Stopwatch() {
  let [hours, setHours] = useState(0);
  let [minutes, setMinutes] = useState(0);
  let [seconds, setSeconds] = useState(0);
  const [start, setStart] = useState(false);
  useEffect(_ => {
    if (start) {
      const intervalToken = setInterval(_ => {
        if (seconds < 59) setSeconds(seconds + 1);
        else if (seconds === 59 && minutes < 59) {
          setSeconds(0);
          setMinutes(minutes + 1);
        } else if (seconds === 59 && minutes === 59) {
          setSeconds(0);
          setMinutes(0);
          setHours(hours + 1);
        }
      }, 1000);
      return _ => {
        clearInterval(intervalToken);
      };
    }
  });
  const zeros = e => {
    setStart(false);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
  };
  return (
    <>
      <div className="container">
        <span className="Nums">
          {hours}
          <sub> h</sub>
        </span>
        <span className="Nums">:</span>
        <span className="Nums">
          {minutes}
          <sub> m</sub>
        </span>
        <span className="Nums">:</span>
        <span className="Nums">
          {seconds}
          <sub> s</sub>
        </span>
      </div>
      <div>
        {start ? (
          ""
        ) : (
          <button className="start_style" onClick={() => setStart(true)}>
            <i className="fa fa-play-circle" />
            &nbsp;Start
          </button>
        )}
        {start ? (
          <button className="stop_style" onClick={() => setStart(false)}>
            <i className="fa fa-stop-circle" />
            &nbsp;Stop
          </button>
        ) : (
          ""
        )}
        <button onClick={zeros}>
          <i className="fa fa-repeat" />
          &nbsp;Reset
        </button>
      </div>
    </>
  );
}
