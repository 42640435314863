import React, { useState } from "react";
import Header from "./Header";
import Stopwach from "./Stopwatch";
import Timer from "./Timer";
import "./styles.css";

export default function App() {
  const [showstop, setShowStop] = useState(false);
  const [showtimer, setShowTimer] = useState(false);
  const [show_hide1, setShow_hide1] = useState("Show");
  const [show_hide2, setShow_hide2] = useState("Show");
  const toggleStopwatch = e => {
    if (showstop === true) {
      setShowStop(false);
      setShow_hide1("Show");
    } else {
      setShowStop(true);
      setShow_hide1("Hide");
    }
  };
  const toggleTimer = e => {
    if (showtimer === true) {
      setShowTimer(false);
      setShow_hide2("Show");
    } else {
      setShowTimer(true);
      setShow_hide2("Hide");
    }
  };
  return (
    <>
      <Header />
      <button className="head_buttons" onClick={toggleStopwatch}>
        {show_hide1} Stopwatch
      </button>
      {showstop ? <Stopwach /> : ""}
      <br />
      <br />
      <button className="head_buttons" onClick={toggleTimer}>
        {show_hide2} Timer
      </button>
      {showtimer ? <Timer /> : ""}
      <address>&copy; Bassel Mostafa</address>
    </>
  );
}
