import React, { useState, useEffect } from "react";

export default function Timer() {
  let [minutes, setMinutes] = useState(2);
  let [seconds, setSeconds] = useState(0);
  const [start, setStart] = useState(false);
  useEffect(_ => {
    if (start) {
      const intervalToken = setInterval(_ => {
        if (seconds === 0 && minutes > 0) {
          setSeconds(59);
          setMinutes(minutes - 1);
        } else if (seconds > 0 && seconds <= 59 && minutes >= 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return _ => {
        clearInterval(intervalToken);
      };
    }
  });
  const zeros = e => {
    setStart(false);
    setMinutes(2);
    setSeconds(0);
  };
  if (seconds === 0 && minutes === 0) {
    return (
      <>
        <div className="container">
          <span className="Nums">
            {minutes}
            <sub> m</sub>
          </span>
          <span className="Nums">:</span>
          <span className="Nums">
            {seconds}
            <sub> s</sub>
          </span>
        </div>
        <div>
          <span id="Time_out">Time out!</span>
          <button onClick={zeros}>
            <i className="fa fa-repeat" />
            &nbsp;Reset
          </button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="container">
          <span className="Nums">
            {minutes}
            <sub> m</sub>
          </span>
          <span className="Nums">:</span>
          <span className="Nums">
            {seconds}
            <sub> s</sub>
          </span>
        </div>
        <div>
          {start ? (
            ""
          ) : (
            <button className="start_style" onClick={() => setStart(true)}>
              <i className="fa fa-play-circle" />
              &nbsp;Start
            </button>
          )}
          {start ? (
            <button className="stop_style" onClick={() => setStart(false)}>
              <i className="fa fa-stop-circle" />
              &nbsp;Stop
            </button>
          ) : (
            ""
          )}
          <button onClick={zeros}>
            <i className="fa fa-repeat" />
            &nbsp;Reset
          </button>
        </div>
      </>
    );
  }
}
